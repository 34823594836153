
import Home from './components/Home';

function App() {
  return (
    <>
      <Home>
      </Home>
    </>
  );
}

export default App;
