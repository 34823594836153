import React from 'react';
import logo from '../images/NOS-Logo-Horizontal-Color-Black.svg';
import adex from '../images/adex.png';
import bt from '../images/blatand.png';
import artbygg from '../images/artbygg-black.png';
import nm1 from '../images/låda-grey-orange-2.png';
import icon from '../images/NOS-Logo-Brandmark-Color-Medium.png';

function Home() {
  return (
    <>
    <div className='navbar'>
      <div className='logo-cont'>
          <a href="/#home"><img src={logo} href='#home' className='logo-small' alt=''></img></a>
      </div>
        <div className='navlinks'>
          <a href='#products'>Products</a>
          <a href='#about-us'>About us</a>
          <a href='#contact'>Get in touch</a>
        </div>
        <div className='btn-cont'>
          <a className='btn' href="#contact">Get in touch</a>
        </div>
    </div>
    <div className='sec' id='home'>
        <div className='cont'>
            <h1 className='title'>Built to help businesses grow their profits.</h1>
            <div className='navmenu'>
              <img src={icon} alt=''></img>
            </div>
        </div>
    </div>
    <div className='sec' id='trusted-by'>
        <div className='cont' id='logos'>
          <img src={adex} alt='adex'></img>
          <img src={bt} alt='blåtand'></img>
          <img src={artbygg} alt='art bygg'></img>
        </div>
    </div>
    <div className='sec' id='products'>
        <h1 className='heading'>Our products</h1>
        <div className='box-cont'>
          <div className='box'>
            <div className='left'>
              <h3 className='subtitle'>Relco</h3>
              <h2 className='box-title'>Websites for craftsmen</h2>
              <p>
                Many craftsmen have websites that are unclear and attract inquiries from the wrong customers. This leads to a waste of time and money. We create websites that bring you the right customers instead.
              </p>
              <a className='btn' href='https://www.relco.se'>Learn more</a>
            </div>
            <div className='right'>
              <h2>We offer:</h2>
              <ul>
                <li>Websites</li>
                <li>Landing pages</li>
              </ul>
            </div>
          </div>
          <div className='box'>
            <div className='left'>
              <h3 className='subtitle'>NOS Machine 1</h3>
              <h2 className='box-title'>Optical berry analysis</h2>
              <p>
              NOS Machine 1 enables powerful analysis of berry content in seconds. Simply place a box of berries in the machine and initiate the analysis from the touchscreen. A photo will be taken under controlled lighting conditions and will automatically be sent to the NOS Platform for an instant analysis of the content. See the results directly on the touchscreen or follow them up on the Web App, anytime and anywhere you want.
              </p>
            </div>
            <div className='right center'>
              <img id='nm1' src={nm1} alt=''></img>
            </div>
          </div>
        </div>
    </div>
    <div className='sec' id='about-us'>
        <div className='box blackbox'>
          <div className='left'>
            <h3 className='subtitle'>About us</h3>
            <h2 className='box-title'>Helping businesses grow</h2>
            <p>
              NOS Technology AB was founded to help other businesses make more money.

              With a background in design, product development, and technology, we use digital tools to achieve this. AI is central to our products and our way of working. Websites and landing pages are one of our services, and the core of our offering for small business owners and craftsmen.
            </p>
          </div>
          <div className='right black'>
            <h2>We help business owners to:</h2>
            <ul>
              <li>Attract customers → Websites and landing pages</li>
              <li>Keep track of customers → Automatically log calls and messages</li>
              <li>Get paid by customers → Ensure all invoices are sent</li>
            </ul>
          </div>
        </div>
    </div>
    <div className='sec' id='contact'>
        <div className='box'>
          <div className='left'>
            <h3 className='subtitle'>Contact</h3>
            <h2 className='box-title'>Get in touch</h2>
            <h4>Karl Hagner, CEO</h4>
            <div className='colinks'>
              <a href='tel:+46730206572'>073-020 65 72</a>
              <a href='mailto:karl@nostechnology.com'>karl@nostechnology.com</a>
            </div>
            <h4>Olof Helleblad Johnson, CPO</h4>
            <div className='colinks'>
              <a href='tel:+46706310282'>070-631 02 82</a>
              <a href='mailto:karl@nostechnology.com'>olof@nostechnology.com</a>
            </div>
          </div>
          <div className='right'>
            <h2>Company</h2>
            <div className='btns'>
              <a className='btn' href='mailto:info@nostechnology.com'>info@nostechnology.com</a>
              <a className='btn' href='mailto:support@nostechnology.com'>support@nostechnology.com</a>
              <a className='btn' href='mailto:info@relco.se'>info@relco.se</a>
            </div>
          </div>
        </div>
    </div>
    <div className='sec'>
      NOS Technology AB {new Date().getFullYear()}
    </div>
    </>
  )
}

export default Home